import React from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import cidade_iot from '../../assets/images/new_weria/cidade_iot_container02_home.png'
import icon_verificado from '../../assets/images/new_weria/icon_verificado_container02_home.png'

import '../../assets/css/general.css'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const TopSeller = props => {
    const data = props.data;
    return (
        <section className='container02_home container_fluid col-md-12'>
            <div className='container02_home_flex row flex-column flex-md-row justify-content-center'>
                <div className='col-md-6 mb-6'>
                    <div>
                        <text className='mb-4' id='container02_home__h2'>Serviços</text>
                    </div>
                    <div>
                        <p className='text-justify mb-4' id='container01_home__p'> Oferecemos suporte contínuo para garantir que nossos clientes estejam sempre atualizados com as tecnologias mais avançadas e possam aproveitar ao máximo suas soluções de IA.</p>
                    </div>
                    <div className='flex col-12 mb-6'>
                        <div className=''>
                            <p className='p__servicos'><img className=' ' id="" src={icon_verificado} alt="nft-gaming" /> Sistema</p>
                        </div>
                        <div className='col-5'>
                            <p className='p__servicos'><img className=' ' id="" src={icon_verificado} alt="nft-gaming" /> IoT</p> 
                        </div> 
                    </div>
                </div>

                <div className='col-md-4 mb-4'>
                    <img className='cidade_iot_container02 flutuar' id="" src={cidade_iot} alt="nft-gaming" />
                </div>
            </div>
        </section>
    );
}

const TopSellerItem = props => (
    <div className="swiper-container seller style2 seller-slider2 button-arow-style">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">										
                    <div className="sc-author-box style-2">
                        <div className="author-avatar">
                                <img src={props.item.img} alt="" className="avatar" />
                            <div className="badge"></div>
                        </div>
                        <div className="author-infor">
                            <h5><Link to="/authors-02">{props.item.name}</Link></h5>
                            <span className="price">{props.item.price}</span>
                        </div>
                    </div>    	
                </div>
            </div>
        </div>
    </div>
)

export default TopSeller;
