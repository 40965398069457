import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Accordion } from 'react-bootstrap-accordion'

const FAQ = () => {
    const [data] = useState(
        [
            {   key: "0",
                show: "show",
                title: 'What is an NFT?',
                text: 'NFTs or non-fungible tokens, are cryptographic assets on blockchain with unique identification codes and metadata that distinguish them from each other. NFTs are unique and not mutually interchangeable, which means no two NFTs are the same.'
            },
            {
                key: "1",
                title: 'Customer support is available ?',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'
            },
            {
                key: "2",
                title: 'How do I find my transaction hash?',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'
            },
            {
                key: "3",
                title: 'What are gas fees on Axies?',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'
            },
            {
                key: "4",
                title: 'What is the effective staking amount?',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'
            },
        ]
    )
    return (
        <div>
            <Header />
            <section className="flat-title-page inner mb-4">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">FAQ</h1>
                            </div> */}
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Política de Privacidade</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className=''>
                <div className='container_fluid'>
                    <div className='col-md-12 mb-4' id='container_01_politica_priv'>
                        <h2 className='h1_politica_priv mb-4'>Informações de identificação pessoal</h2>
                        <p className='text-justify'>Podemos coletar suas informações de identificação pessoal de várias maneiras, incluindo, mas não se limitando a, quando você compra nossos produtos/serviços, quando visita nosso Site, registra-se no Site, utiliza outras atividades, serviços, recursos ou recursos que disponibilizar em nosso Site, ou quando você solicitar informações ou se comunicar conosco. Conforme apropriado, você pode ser solicitado e podemos processar as seguintes categorias de informações de identificação pessoal: nome, endereço de e-mail, endereço para correspondência, número de telefone, empresa e cargo. Você pode, no entanto, visitar o nosso site de forma anônima. Coletaremos suas informações de identificação pessoal apenas se você nos enviar voluntariamente essas informações. Você sempre pode se recusar a fornecer informações de identificação pessoal, exceto que isso pode impedi-lo de se envolver em determinadas atividades relacionadas ao Site.</p>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <h2  className='h1_politica_priv mb-4'>Informações de identificação não pessoais</h2>
                        <p className='text-justify'>Podemos coletar informações de identificação não pessoal sobre você sempre que você interagir com nosso Site. As informações de identificação não pessoal podem incluir o nome do navegador, o tipo de computador e informações técnicas sobre seus meios de conexão ao nosso Site, como o sistema operacional e os provedores de serviços de Internet utilizados e outras informações semelhantes.</p>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <h2 className='h1_politica_priv mb-4'>Cookies do navegador</h2>
                        <p className='text-justify'>Nosso Site pode usar “cookies” para melhorar sua experiência. Seu navegador da Web coloca cookies em seu disco rígido para fins de manutenção de registros e, às vezes, para rastrear informações sobre eles. Você pode optar por configurar seu navegador da Web para recusar cookies ou para alertá-lo quando os cookies estiverem sendo enviados. Se você fizer isso, observe que algumas partes do Site podem não funcionar corretamente.a</p>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <h2 className='h1_politica_priv mb-4'>Como usamos as informações coletadas</h2>
                        <p className='mb-3 text-justify'>Weria tecnologia pode coletar e usar suas informações pessoais para os seguintes fins:</p>
                        <ul className='mb-4'>
                            <p className='itens__p__politica_priv'>- Para entregar e fornecer produtos/serviços, para manter e dar suporte aos nossos produtos, para cumprir nossas obrigações contratuais relacionadas a eles ou para nos comunicarmos com o objetivo de estabelecer relações contratuais</p>
                            <p className='itens__p__politica_priv'>- Para se registrar em nosso Site</p>
                            <p className='itens__p__politica_priv'>- Para melhorar nosso Site</p>
                            <p className='itens__p__politica_priv'>- Nós nos esforçamos continuamente para melhorar as ofertas de nosso site com base nas informações e comentários que recebemos de você.​</p>
                            <p className='itens__p__politica_priv'>- Para melhorar o atendimento ao cliente</p>
                            <p className='itens__p__politica_priv'>- Suas informações nos ajudam a responder com mais eficácia às suas solicitações de atendimento ao cliente e necessidades de suporte.​</p>
                            <p className='itens__p__politica_priv'>- Para enviar e-mails periódicos</p>
                            <p className='itens__p__politica_priv'>- O endereço de e-mail que você fornecer será usado apenas para responder às suas perguntas e/ou outras solicitações ou perguntas. Você só receberá material de marketing se der seu consentimento para o recebimento de tal material.​</p>
                            <p className='itens__p__politica_priv text-justify'>- Para cumprir de outra forma os requisitos legais aplicáveis​</p>
                        </ul>
                        <p className='text-justify'>Se usarmos seus Dados Pessoais para uma finalidade que seja materialmente diferente da finalidade para a qual foram coletados, forneceremos a você a oportunidade de optar por não participar​.</p>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <h2 className='h1_politica_priv mb-4'>Como protegemos suas informações</h2>
                        <p className='text-justify'>Adotamos práticas adequadas de coleta, armazenamento e processamento de dados e medidas de segurança para proteger contra acesso não autorizado, alteração, divulgação ou destruição de suas informações pessoais, nome de usuário, senha, informações de transações e dados armazenados em nossa infraestrutura. Qualquer troca de dados privados confidenciais é transmitida por um canal de comunicação protegido por SSL criptografado.</p>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <h2 className='h1_politica_priv mb-4'>Compartilhando suas informações pessoais</h2>
                        <p className='text-justify'>Não vendemos, trocamos ou alugamos informações pessoais a terceiros. Podemos transferir informações pessoais para agentes terceirizados ou prestadores de serviços para os fins descritos acima. Quando exigido pelas leis ou regulamentos de proteção de dados aplicáveis, celebramos acordos de confidencialidade estritos com esses agentes terceirizados e provedores de serviços, exigindo que eles forneçam níveis adequados de proteção de dados e limitando o uso dos dados aos serviços especificados em nosso nome. Tomamos medidas razoáveis ​​e apropriadas para garantir que agentes terceirizados e provedores de serviços processem informações pessoais de acordo com as leis e regulamentos de proteção de dados aplicáveis ​​e para interromper e remediar qualquer processamento não autorizado.</p>
                        <br/>
                        <p className='text-justify'>Sob certas circunstâncias, podemos ser obrigados a divulgar suas informações pessoais em resposta a solicitações válidas de autoridades públicas, inclusive para atender a segurança nacional, aplicação da lei ou outros requisitos governamentais.</p>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <h2 className='h1_politica_priv mb-4'>Alterações a esta política de privacidade</h2>
                        <p className='text-justify'>Weria Tecnologia tem o poder de atualizar esta política de privacidade a qualquer momento. Quando o fizermos, revisaremos a data atualizada na parte inferior desta página. Se houver alterações materiais, iremos notificá-lo por e-mail, por meio de um aviso no site ou por outros meios. Incentivamos você a verificar frequentemente esta página para verificar se há alterações para se manter informado sobre como estamos ajudando a proteger as informações pessoais que coletamos.</p>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <h2 className='h1_politica_priv mb-4'>Sua aceitação destes termos</h2>
                        <p className='text-justify'>Ao usar este Site, você indica sua aceitação desta política. Se você não concorda com esta política, por favor, não use nosso Site. Seu uso continuado do Site após a publicação das alterações a esta política será considerado sua aceitação dessas alterações.</p>
                    </div>
                    <div className='col-md-12 mb-4'>
                        <h2 className='h1_politica_priv mb-4'>Entre em Contato Conosco</h2>
                        <p className='text-justify'>Se você tiver alguma dúvida sobre esta Política de Privacidade, as práticas deste Site ou suas relações com este Site, entre em contato com nosso Diretor de Proteção de Dados em:</p>
                        <p className='itens__p__politica_priv'>contato@weria.com</p>
                        <p className='itens__p__politica_priv'>Brasília - DF, Asa Norte 702/703, Conjunto G</p>
                        <p className='itens__p__politica_priv'>Acesso 46</p>
                    </div>
                    <div className='col-md-11 mb-4'>
                        <p className='date_edit_politica_priv'>Este documento foi atualizado pela última vez em 30 de março de 2023.</p>
                    </div>
                </div>
            </section>
       
            <Footer />
        </div>
    );
}

export default FAQ;
