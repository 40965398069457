import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import logoBZ from '../../../assets/images/logo/logoBz.png'
import logoNewin from '../../../assets/images/logo/logoNewin.png'
import logoAres from '../../../assets/images/logo/logoAres.png'

const PopularCollection = props => {
    const data = props.data;
    return (
        <section className="tf-section" id='container_parceiros'>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        {/* <div className="heading-live-auctions">
                            <h2 className="tf-title pb-22 text-center">
                                Parceiros</h2>
                            {/* <Link to="/explore-03" className="exp style2">EXPLORE MORE</Link> 
                        </div> */}
                    </div>
                    <div className="col-md-12">
                        <div className="collection">
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y]}
                            spaceBetween={30}
                            scrollbar={{ draggable: true }}
                        >
                            {
                                data.map((item,index) => (
                                    <SwiperSlide key={index}>
                                        <PopularCollectionItem item={item} />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        </div>    
                    </div>
                </div>
            </div>
        </section>
    );
}
PopularCollection.propTypes = {
    data: PropTypes.array.isRequired,
}

const PopularCollectionItem = props => (
    <div className="swiper-container show-shadow carousel4 button-arow-style">
        <div className="swiper-wrapper">
            <div className="swiper-slide container_fluid col-md-12 ">
                <h1 className='text-center mb-4 h1_parceiros'>Parceiros</h1>
                <div className="slider-item row flex-column flex-md-row justify-content-center align-item-center ">
                    <div className='col-md-3 mb-4'>	
                        <img className='' id="" src={logoAres} alt="Ares"/>	
                    </div>	
                    <div className='col-md-3 mb-4'>					
                        <img className='' id="" src={logoBZ} alt="Bz Tecnlogia"/>	
                    </div>		
                    <div className='col-md-3 mb-4'>					
                        <img className='' id="" src={logoNewin} alt="Newin"/>
                    </div>								
                </div>
            </div>
        </div>                            
    </div>
)

export default PopularCollection;
