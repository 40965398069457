import React from 'react';
import { Link } from 'react-router-dom'
import imagemSkill from '../../../assets/images/new_weria/imagem-nossas-skils.png'

const TopSeller = props => {
    const data = props.data;
    return (
        <section className='container02_home container_fluid col-md-12'>
        <div className='container02_home_flex row flex-column flex-md-row justify-content-center'>
            <div className='col-md-6 mb-6'>
                <div>
                    <text className='mb-4' id='container02_home__h2'>Nossas Skills</text>
                </div>
                <div>
                    <p className='text-justify mb-4' id='container01_home__p'> Nossa empresa tem uma ampla gama de habilidades e conhecimentos especializados para ajudar nossos clientes a alcançar seus objetivos de negócios. Nossa equipe é composta por especialistas em inteligência artificial, aprendizado de máquina, análise de dados e engenharia de software, todos trabalhando juntos para criar soluções inovadoras e personalizadas.</p>
                </div>
                <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Visão computacional</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>95%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress"></div>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Ciência de Dados</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>90%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress02"></div>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Automação de Processos</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>98%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress_automacao"></div>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Gerenciamento de Projetos</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>93%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress_gerenciamento"></div>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Inteligência Artificial</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>99%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress_IA"></div>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Marketing e Vendas</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>90%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress02"></div>
                    </div>
            </div>
            <div className='col-md-4 mb-4'>
                <img className='cidade_iot_container02 flutuar' id="" src={imagemSkill} alt="nft-gaming" />
            </div>
        </div>
    </section>
    );
}

const TopSellerItem = props => (
    <div className={`box-item ${props.item.classPadding}`}>
        <div className="sc-author-box style-3 pd-0">
            <div className="author-avatar">
                <Link to="/authors-02">
                    <img src={props.item.img} alt="axies" className="avatar" />
                </Link>
                <div className="badge"><i className="ripple"></i></div>
            </div>
            <div className="author-infor">
                <h5 className="fs-16"><Link to="/authors-02">{props.item.name}</Link></h5>
                <span className="price">{props.item.price}</span>
            </div>
        </div>
    </div>  
)

export default TopSeller;
