import React , { useState , Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y , Autoplay } from 'swiper';
import Countdown from "react-countdown";
import CardModal from '../CardModal';
import casaIot from '../../../assets/images/new_weria/imagem_casa_iot_weria.png'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const LiveAuction = props => {
    const data = props.data;

    const [modalShow, setModalShow] = useState(false);

    return (
        <section className='container_home container_fluid col-md-12'>
        <div className='container01_nossa_empresa row flex-column flex-md-row justify-content-center'>
            <div className='col-md-4 mb-4'>
                <img className='IA_container01 flutuar' id="" src={casaIot} alt="nft-gaming" />
            </div>

            <div className='col-md-5 mb-5'>
                <div>
                    <h2 className='mb-4' id='contaienr01_home__h2'>Impulsionados por inteligência artificial e humanos</h2>
                </div>
                <div>
                    <p className='text-justify mb-4' id='container01_home__p'>Aqui, você encontrará uma ampla seleção de soluções de IA que visam melhorar e automatizar processos em diferentes setores. Desde assistentes virtuais até sistemas de análise de dados, passando por chatbots e plataformas de reconhecimento de imagem e voz, nossos produtos são desenvolvidos com as tecnologias mais avançadas de IA, garantindo qualidade e desempenho superiores. Oferecemos soluções para empresas de diversos segmentos, desde pequenas startups até grandes corporações, ajudando-as a aumentar a eficiência de seus processos, otimizar seus custos e melhorar a experiência do usuário. Além disso, nossos produtos são projetados para serem intuitivos e fáceis de usar, mesmo para aqueles que não têm experiência prévia em IA. Com nossas soluções, você pode automatizar tarefas repetitivas, tomar decisões baseadas em dados e fornecer serviços personalizados para seus clientes, levando sua empresa para o próximo nível de sucesso.</p>
                </div>
            </div>
        </div>
        <div className='container02_nossa_empresa container_fluid  row flex-column flex-md-row justify-content-center' >
            <div className='col-md-3 mb-4'>
                <h1 className='text-center' id='container02_nossa_empresa__indice_number'>+400%</h1>
                <h4 className='text-center' id='container02_nossa_empresa__texto_indice'>Aumento na retenção de usuários</h4>
            </div>
            <div className='col-md-3 mb-4'>
                <h1 className='text-center' id='container02_nossa_empresa__indice_number'>+5%</h1>
                <h4 className='text-center' id='container02_nossa_empresa__texto_indice'>Aumento na conversão de atualização</h4>
            </div>
            <div className='col-md-3 mb-4'>
                <h1 className='text-center' id='container02_nossa_empresa__indice_number'>+300k</h1>
                <h4 className='text-center' id='container02_nossa_empresa__texto_indice'>Economizado anualmente</h4>
            </div>
        </div>
    </section>
    );
}

LiveAuction.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LiveAuction;
