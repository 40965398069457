import img1 from '../images/logo/logo_weria_laranja.png';
import img2 from '../images/slider/slide_2.png';
import imgbg1 from '../images/slider/bg_slide_2.png'
import imgbg2 from '../images/slider/bg_slide_2.png'


const heroSliderData = [
    {
        title_1: "Conheça a nossa ",
        // title_2: "extraordinary",
        title_3: "Empresa",
        // title_4: "NFTs",
        description: "Incentivando a criatividade e a inovação!",
        // img: img1,
        imgbg: imgbg1,
        class: 'left'
    },
    {
        title_1: "Conheça a nossa",
        title_2: "",
        title_3: "Empresa ",
        // title_4: "NFTs",
        description: "Incentivando a criatividade e a inovação",
        img: img2,
        imgbg: imgbg2,
        class: 'center'
    },
    {
        title_1: "Incentivando",
        title_2: "A criatividade",
        title_3: "Monster ",
        title_4: "NFTs",
        description: "Marketplace for monster character cllections non fungible token NFTs",
        img: img2,
        imgbg: imgbg2,
        class: 'right'
    },
]

export default heroSliderData;