import img1 from '../images/slider/slide_4.png';
// import img2 from '../images/slider/slide_1.png';
import imgbg1 from '../images/slider/bg_slide_1.png'
// import imgbg2 from '../images/slider/bg_slide_1.png'


const heroSliderData = [
    {
        title_1: "Incentivando",
        title_2: "A criatividade",
        title_3: "E a inovação",
        description: "A criatividade é o combustível que impulsiona a inovação, e em uma empresa de tecnologia, a inovação é a chave para o sucesso.",
        img: img1,
        imgbg: imgbg1,
        class:'left'
    },
    // {
    //     title_1: "Incentivando",
    //     title_2: "A creatividade",
    //     title_3: "E a inovação",
    //     description: "A criatividade é o combustível que impulsiona a inovação, e em uma empresa de tecnologia, a inovação é a chave para o sucesso.",
    //     img: img2,
    //     imgbg: imgbg2,
    //     class:'center'
    // },
    // {
    //     title_1: "Incentivando",
    //     title_2: "A criatividade",
    //     title_3: "E a inovação",
    //     description: "A criatividade é o combustível que impulsiona a inovação, e em uma empresa de tecnologia, a inovação é a chave para o sucesso.",
    //     img: img2,
    //     imgbg: imgbg2,
    //     class:'right'
    // },
    
]

export default heroSliderData;