const menus = [
    {
        id: 1,
        name: 'Sobre Nós',
        links: '#',
        class:'center',
        namesub: [
            {
                id: 1,
                sub: 'Nosso Time',
                links: '/home-03'
            },
            {
                id: 2,
                sub: 'Nossa Empresa',
                links: '/home-02'
            },
        
        ],
    
    },
    {
        id: 2,
        name: 'Produtos',
        links: '/blog',
       
    },{
        id: 3,
        name: 'Contato',
        links: '/contact-01',
      
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Activity 01',
        //         links: '/activity-01'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Activity 02',
        //         links: '/activity-02'
        //     }
        // ],
    },
    {
        id: 4,
        name: 'Política de Privacidade',
        links: '/FAQ',
       
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Blog',
        //         links: '/blog'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Blog Details',
        //         links: '/blog-details'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Help Center',
        //         links: '/help-center'
        //     }
        // ],
    },
    // {
    //     id: 5,
    //     name: 'Page',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Authors 01',
    //             links: '/authors-01'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Authors 02',
    //             links: '/authors-02'
    //         },
    //         {
    //             id: 3,
    //             sub: 'Wallet Connect',
    //             links: '/wallet-connect'
    //         },
    //         {
    //             id: 4,
    //             sub: 'Create Item',
    //             links: '/create-item'
    //         },
    //         {
    //             id: 5,
    //             sub: 'Edit Profile',
    //             links: '/edit-profile'
    //         },
    //         {
    //             id: 6,
    //             sub: 'Ranking',
    //             links: '/ranking'
    //         },
    //         {
    //             id: 7,
    //             sub: 'Login',
    //             links: '/login'
    //         },
    //         {
    //             id: 8,
    //             sub: 'Sign Up',
    //             links: '/sign-up'
    //         },
    //         {
    //             id: 9,
    //             sub: 'No Result',
    //             links: '/no-result'
    //         },
    //         {
    //             id: 10,
    //             sub: 'FAQ',
    //             links: '/faq'
    //         }
    //     ],
    // },
    // {
    //     id: 7,
    //     name: 'Contact',
    //     links: '/contact-01',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Contact 1',
    //             links: '/contact-01'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Contact 2',
    //             links: '/contact-02'
    //         }
    //     ],
    // },
    
]

export default menus;