import React from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../../assets/images/icon/Wallet.png'
import icon2 from '../../../assets/images/icon/Category.png'
import icon3 from '../../../assets/images/icon/Image2.png'
import icon4 from '../../../assets/images/icon/Bookmark.png'

const Create = () => {
    const data = [
        {
            title: "Set Up Your Wallet",
            description: "Once you’ve set up your wallet of choice, connect it to OpenSeaby clicking the NFT Marketplacein the top right corner.",
            icon : icon1,
            colorbg : "icon-color1"
        },
        {
            title: "Create Your Collection",
            description: "Click Create and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.",
            icon : icon2,
            colorbg : "icon-color2",
        },
        {
            title: "Add Your NFTs",
            description: "Upload your work (image, video, audio, or 3D art), add a title and description, and customize your NFTs with properties, stats",
            icon : icon3,
            colorbg : "icon-color3",
        },
        {
            title: "List Them For Sale",
            description: "Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs!",
            icon : icon4,
            colorbg : "icon-color4",
        },
    ]
    return (
        <section className='container01_sobreNos_pai container_fluid col-md-12'>
            <div className='container01_sobreNos_flex row flex-column flex-md-row justify-content-center'>
                <div className='col-md-3 mb-3'>
                    <h3 className='mb-4' id='contaienr01_sobreNos__h3'>Porque Nos Escolher?</h3>
                    <div>
                        <p className='text-justify mb-4' id='container01_home__p'>Existem muitas empresas de IA no mercado, mas nossa empresa se destaca por ter uma equipe de especialistas altamente qualificados em diversas áreas, incluindo inteligência artificial, aprendizado de máquina, análise de dados e engenharia de software.</p>
                    </div>
                </div>
                <div className='col-md-3 mb-3'>
                    <h3 className='mb-4' id='contaienr01_sobreNos__h3'>Nossos Valores</h3>
                    <div>
                        <p className='text-justify mb-4' id='container01_home__p'>Em nossa empresa, acreditamos que nossos valores são o que nos guiam em nosso trabalho e nos ajudam a alcançar nossos objetivos. Nós nos esforçamos para sempre manter esses valores em mente em tudo o que fazemos.
                        <br></br>
                        - Transparência
                        <br></br>
                        - Integridade
                        <br></br>
                        - Comprometimento com a excelência...</p>
                    </div>
                </div>
                <div className='col-md-3 mb-3'>
                    <h3 className='mb-4' id='contaienr01_sobreNos__h3'>O Que Nos Move?</h3>
                    <div>
                        <p className='text-justify mb-4' id='container01_home__p'>É a possibilidade de ajudar nossos clientes a alcançar seus objetivos de negócios de maneira mais eficaz e eficiente. Estamos entusiasmados com o potencial da inteligência artificial para transformar a maneira como as empresas operam e estamos comprometidos em ajudar nossos clientes a aproveitar ao máximo essa tecnologia.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

const CreateItem = props => (
    <div className='col-lg-3 col-md-6 col-12'>
        <div className="sc-box-icon">
        <div className="image center">
            <div className={`icon-create ${props.item.colorbg}`}>
                    <img src={props.item.icon} alt="" />
                </div>                                                                           
            </div>
        <h3 className="heading"><Link to="/wallet-connect">{props.item.title}</Link></h3>
        <p className="content">{props.item.description}</p>
    </div>
    </div>
    
)

export default Create;
