import React , { useState , Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";
import CardModal from '../CardModal';
import lucasgasp from '../../../assets/images/new_weria/imagem-lucas-gasparoni.png'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const LiveAuction = props => {
    const data = props.data;

    const [modalShow, setModalShow] = useState(false);

    return (
       <section className='container_sobreNos container_fluid col-md-12 bg-home-3 tf-section top-seller'>
        <div className='container01_home_flex row flex-column flex-md-row justify-content-center'>
            <div className='col-md-6 mb-6 text-justify'>
                <div className='mb-4' id='titulo_subtitutlo_lucas'>
                    <h2>Lucas Gasparoni</h2>
                    <h4 className=''>Diretor Executivo e Fundador</h4>
                </div> 
                <div>
                <p className='text-justify mb-4' id='text_lucas'>Um fundador apaixonado por tecnologia, com uma longa história de interesse em inteligência artificial (IA). Desde jovem, ele se interessou pelo potencial da IA para transformar a maneira como as empresas operam e como as pessoas vivem suas vidas cotidianas.
                Com uma equipe de especialistas em IA altamente qualificados, Lucas fundou sua empresa com a missão de tornar a IA mais acessível e fácil de usar para empresas de todos os setores. Desde então, a empresa cresceu, atendendo a uma ampla gama de clientes em todo o mundo.</p>
                </div>
            </div>
            <div className='col-md-4 mb-4 d-flex'>
                <img className='mx-auto' id="logo_footer" src={lucasgasp} alt="nft-gaming" />
             </div>
        </div>
        
       </section>
    );
}

LiveAuction.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LiveAuction;
