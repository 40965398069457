import React from 'react';
import { Link } from 'react-router-dom'

const TopSeller = props => {
    const data = props.data;
    return (
        <section className="container_fluid col-md-12" id='container03_sobreNos_pai'>
            <div className=' container03_sobreNos row flex-column flex-md-row justify-content-center'>
                <div className='col-md-3 mb-3 '>
                <article class="card">
			        <div class="card__body">
				        <h2 class="card__title">Lucas Tauan</h2>
                        <h4 className='card_subtitle'>Product Owner</h4>
				        <p className='text-white'>
                        <i class="fab fa-facebook-f"></i> userface<br/>
                        <i class="fab fa-instagram"></i> @abc <br/>
                        <i class="fab fa-google"></i> Lucas@gmail.com <br/>
                        <i class="fab fa-linkedin"></i><a href={"https://www.linkedin.com/in/lucas-tauan-fernandes-54b99037/"} target="_blank"> Lucas Tauan</a> 
				        </p>
			        </div>
		        </article>
                </div>
                <div className='col-md-3 mb-3 '>
                <article class="card">
			        <div class="card__body">
				        <h2 class="card__title">Isis Silva</h2>
                        <h4 className='card_subtitle'>Desenvolvedora Full-Stack</h4>
				        <p className='text-white'>
                        {/* <i class="fab fa-facebook-f"></i> userface<br/> */}
                        <i class="fab fa-instagram"></i> @isis_sis <br/>
                        <i class="fab fa-google"></i> isilar57@gmail.com <br />
                        <i class="fab fa-linkedin"></i><a href={"https://www.linkedin.com/in/isis-alves-/"} target="_blank"> Isis Silva</a>
				        </p>
			        </div>
		        </article>
                </div>
                <div className='col-md-3 mb-3'>
                <article class="card">
			        <div class="card__body">
				        <h2 class="card__title">Luigi Vila</h2>
                        <h4 className='card_subtitle'>Desenvolvedor Front-End</h4>
				        <p className='text-white'>
                        {/* <i class="fab fa-facebook-f"></i> userface<br/> */}
                        <i class="fab fa-instagram"></i> @Vila_luigi <br/>
                        <i class="fab fa-google"></i> vilaluigi@gmail.com <br />
                        <i class="fab fa-linkedin"></i><a href={"https://www.linkedin.com/in/luigi-vila-camargo-99b4211b1/"} target="_blank"> Luigi Vila</a>
				        </p>
			        </div>
		        </article>
                </div>
                <div className='col-md-3 mb-3'>
                <article class="card">
			        <div class="card__body">
				        <h2 class="card__title">Douglas Santos</h2>
                        <h4 className='card_subtitle'>Desenvolvedor Front-End</h4>
				        <p className='text-white'>
                        {/* <i class="fab fa-facebook-f"></i> userface<br/> */}
                        <i class="fab fa-instagram"></i> @D0uglas_0203 <br/>
                        <i class="fab fa-google"></i> douglasas410@gmail.com <br />
                        <i class="fab fa-linkedin"></i><a href={"https://www.linkedin.com/in/douglas-santos-813539234/"} target="_blank"> Douglas Santos</a>
				        </p>
			        </div>
		        </article>
                </div>
            </div>
        </section>
    );
}

const TopSellerItem = props => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
        <div className="sc-author-box">
            <div className="author-avatar">
                <Link to="/authors-02">
                    <img src={props.item.img} alt="Axies" className="avatar" />
                </Link>
                <div className="badge"><i className="ripple"></i></div>
            </div>
            <div className="author-infor">
                <h5 className="style2"><Link to="/authors-02">{props.item.name}</Link></h5>
                <span className="price">{props.item.price}</span>
            </div>
        </div>    
    </div>
)

export default TopSeller;
