import React , { useState , Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CardModal from './CardModal';

import '../../assets/css/general.css'
import '../../assets/js/contador.js'

const TodayPicks = props => {
    const data = props.data;

    const [visible , setVisible] = useState(8);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }
    const [modalShow, setModalShow] = useState(false);
    return (
      <section className='container03_home container_fluid col-md-12'>
        <div>
            <h1 className='text-center mb-4' id='container03_home__h1'>Nossas Estatísticas</h1>
        </div>
        <div className='row justify-content-center row flex-column flex-md-row justify-content-center'>
            <div className='col-md-2 mb-4 text-center' id='numeros'>
                <span className='h1' id='contador_numeros'>+250</span>
                <p className='container03_home__p'>Projetos</p>
            </div>
            <div className='col-md-2 mb-4 text-center' id='numeros'>
                <span className='h1' id='contador_numeros'>+100</span>
                <p className='container03_home__p'>Clientes Satisfeitos</p>
            </div>
            <div className='col-md-2 mb-4 text-center' id='numeros'>
                <span className='h1' id='contador_numeros'>+10.000</span>
                <p className='container03_home__p'>Horas Trabalhadas</p>
            </div>
            <div className='col-md-2 mb-4 text-center' id='numeros'>
                <span className='h1' id='contador_numeros'>+30.000</span>
                <p className='container03_home__p'>Linhas de Códigos</p>
            </div>
        </div>
      </section>
    );
}



TodayPicks.propTypes = {
    data: PropTypes.array.isRequired,
}


export default TodayPicks;
