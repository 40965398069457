import React , { useState , Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";
import CardModal from './CardModal'
import IA from '../../assets/images/new_weria/robo_container01_home.png'

import '../../assets/css/general.css'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const LiveAuction = props => {
    const data = props.data;

    const [modalShow, setModalShow] = useState(false);

    return (
        <section className='container_home container_fluid col-md-12'>
            <div className='container01_home_flex row flex-column flex-md-row justify-content-center'>
                <div className='col-md-4 mb-4'>
                    <img className='IA_container01 flutuar' id="" src={IA} alt="nft-gaming" />
                </div>

                <div className='col-md-6 mb-6'>
                    <div>
                        <h2 className='mb-4' id='contaienr01_home__h2'>Construindo um futuro automatizado</h2>
                    </div>
                    <div>
                        <p className='text-justify mb-4' id='container01_home__p'>Na nossa empresa, estamos comprometidos em ajudar nossos clientes a construir um futuro melhor usando a tecnologia de IA e IOT. Acreditamos que a IA tem o potencial de revolucionar muitas indústrias, desde a saúde até a manufatura, e estamos trabalhando para criar soluções que possam ajudar nossos clientes a aproveitar ao máximo essa tecnologia.
                        Acreditamos que a IA pode ser uma força para o bem, mas é importante garantir que a tecnologia seja desenvolvida e usada de maneira ética e responsável. Estamos comprometidos em trabalhar com nossos clientes, parceiros e outras partes interessadas para garantir que a IA e IOT seja, desenvolvidas de forma responsável e que seja usada para melhorar a vida das pessoas.</p>
                    </div>
                    <div>
                        <ul>
                            <p className='container01_home_ul__p mb-2'>- Weria Devops</p>
                            <p className='container01_home_ul__p mb-2'>- Weria IA</p>
                            <p className='container01_home_ul__p mb-2'>- Weria IoT</p>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

LiveAuction.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LiveAuction;
