import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import img1 from '../assets/images/blog/thumb-8.png';
import imagemcontato02 from '../assets/images/new_weria/imagem_weria_contato02.png';
import endereco from '../assets/images/new_weria/icons/pin-de-localizacao.png'
import phone from '../assets/images/new_weria/icons/telephone.png'
import email from '../assets/images/new_weria/icons/mail.png'

const Contact01 = () => {
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Contact 1</h1>
                            </div> */}
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Contato</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className='container_fluid col-md-12 text-center' id='container01_contato'>
                        <div className='row flex-column flex-md-row justify-content-center'>
                            <div className='col-md-4 mb-4'>
                                <div><img src={endereco} alt="Endereço" className='flutuar'/></div>
                                <div><h3>Endereço</h3></div>
                                <div><p>Brasília - DF, Asa Norte 702/703, Conjunto G <br></br> Acesso 46</p></div>
                            </div>
                            <div className='col-md-4 mb-4'>
                                <div><img src={phone} alt="Endereço" className='flutuar'/></div>
                                <div><h3>Número de Telefone</h3></div>
                                <div><p>(61) 983463082</p></div>
                            </div>
                            <div className='col-md-4 mb-4'>
                                <div><img src={email} alt="Endereço" className='flutuar'/></div>
                                <div><h3>Email</h3></div>
                                <div><p>contato@werial.com</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="box-feature-contact">
                                <img src={imagemcontato02} alt="Axies" className='flutuar' />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <h2 className="tf-title-heading style-2 mg-bt-12">
                                Fale Conosco
                            </h2>
                            <h5 className="sub-title style-1">
                            Nós gostamos de ouvir de você. Nos fale mais sobre seu projeto ou dúvida.
                            </h5>
                            <div className="form-inner">
                                <form id="contactform" noValidate="novalidate" className="form-submit" >
                                    <input id="name" name="name" tabIndex="1" aria-required="true" type="text" placeholder="Nome Completo" required />
                                    <input id="email" name="email" tabIndex="2"  aria-required="true" type="email" placeholder="Endereço de Email" required />
                                    {/* <div className="row-form style-2" id="subject">
                                        <select>
                                            <option value="1">Select subject</option>
                                            <option value="2">Select subject</option>
                                            <option value="3">Select subject</option>
                                        </select>
                                        <i className="icon-fl-down"></i>
                                    </div> */}
                                    <textarea id="message" name="message" tabIndex="3" aria-required="true" required placeholder="Menssagem"></textarea>
                                    <button className="submit">Enviar Menssagem</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            
            <Footer />
        </div>
    );
}

export default Contact01;
