import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import imagemCerebro from '../../assets/images/new_weria/cerebro_container03_home.png'

import '../../assets/js/contador.js'
import '../../assets/css/general.css'

import { ProgressBar } from 'react-bootstrap';

const PopularCollection = props => {
    const data = props.data;
    return (
        <section className='container_home container_fluid col-md-12'>
            <div className='container04_home_flex row flex-column flex-md-row justify-content-center'>
                <div className='col-md-4 mb-4'>
                    <img className='imagemCerebro_container04 flutuar' id="" src={imagemCerebro} alt="nft-gaming" />
                </div>

                <div className='col-md-6 mb-6' id='container04_home__nossos_numeros'>
                    <div>
                        <h2 className='mb-4' id='contaienr04_home__h2'>Nossas Skils</h2>
                    </div>
                    <div>
                        <p className='text-justify mb-4' id='container04_home__p'>Com nossa equipe de especialistas, estamos prontos para enfrentar os desafios mais complexos e ajudar nossos clientes a transformar seus negócios com soluções de IA sob medida para suas necessidades.</p>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Inovação e Criatividade</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>95%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress"></div>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Gerenciamento de projetos</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>90%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress02"></div>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Visão de mercado</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>92%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress03"></div>
                    </div>
                    <div className='row justify-content-around mb-3'>
                        <div className='col-7'>
                            <h3>Flexibilidade</h3>
                        </div>
                        <div>
                            <h3 className='procentagem'>94%</h3>
                        </div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress04"></div>
                    </div>
                </div>
            </div>
            
        </section>
    );
}
PopularCollection.propTypes = {
    data: PropTypes.array.isRequired,
}

const PopularCollectionItem = props => (
    <div className="swiper-container show-shadow carousel4 button-arow-style">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">										
                    <div className="sc-card-collection style-2 home2">
                        <div className="card-bottom">
                            <div className="author">
                                <div className="sc-author-box style-2">
                                    <div className="author-avatar">
                                        <img src={props.item.imgAuthor} alt="" className="avatar" />
                                        <div className="badge"><i className="ripple"></i></div>
                                    </div>
                                </div>
                                <div className="content">
                                    <h4><Link to="/authors-01">{props.item.title}</Link></h4>
                                    <div className="infor">
                                        <span>Created by</span>
                                        <span className="name"><Link to="/authors-02">{props.item.name}</Link></span>
                                    </div>
                                </div>
                            </div>
                            <Link to="/login" className="wishlist-button public heart"><span className="number-like"> 100</span></Link>
                        </div>
                        <Link to="/authors-02">
                            <div className="media-images-collection">
                                <div className="box-left">
                                    <img src={props.item.imgleft} alt="axies" />
                                </div>
                                <div className="box-right">
                                    <div className="top-img">
                                        <img src={props.item.imgright1} alt="axies" />
                                        <img src={props.item.imgright2} alt="axies" />
                                    </div>
                                    <div className="bottom-img">
                                        <img src={props.item.imgright3} alt="axies" />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div> 	
                </div>
            </div>
        </div>                            
    </div>
)

export default PopularCollection;
