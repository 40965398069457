import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import blogData from  '../assets/fake-data/data-blog'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import weria_devops from '../assets/images/new_weria/imagem_weria_devops.png'
import weria_ia from '../assets/images/new_weria/imagem_weria_ia.png'
import weria_iot from '../assets/images/new_weria/imagem_weria_iot.png'

import '../assets/css/general.css'
import '../assets/js/contador'

const Blog = () => {
    const [data] = useState(blogData);

    const [visible , setVisible] = useState(6);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);
    }
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Produtos</h1>
                            </div> */}
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Produtos</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <div className='container_fluid col-md-12 justify-content-center '>
                <div className='col-12'>
                    <h1 className='text-center text-justify mb-3 ' id='container01_produtos__h1'>Nossos Produtos</h1>
                </div>
                <div className='linha mb-3'></div>
                <div className='d-flex col-12' id='container01_produtos__text'>
                    <p className='mx-auto text-center text-justify col-10'>Aqui, você encontrará uma ampla seleção de soluções que visam melhorar e automatizar processos em diferentes setores!</p>
                </div>
                <div className='row justify-content-center flex-column flex-md-row ' id='container02_pai_produtos'>
                {/* <div className='row justify-content-center mb-4 col-12' id='container02_pai_produtos'> */}
                    <div className='col-md-4 mb-4'>
                    {/* <div className='col-3 col-md-3 col-lg-3'> */}
                        <div className='d-flex'><img className=' flutuar mb-4 mx-auto' id="" src={weria_devops} alt="nft-gaming" /></div>
                        <div>
                            <h2 className='mb-4 text-center' id='container02_produtos_h2'>Weria Devops</h2>
                            <p className='text-justify'>Os maiores desafios dos negócios modernos é entregar software de alta qualidade e com rapidez, onde a abordagem tradicional de desenvolvimento de software, na qual as equipes de desenvolvimento e operações são separadas, não conseguia atender às demandas do mercado. Pensando nisso criamos a oferta Weria DevOps, um conjunto de práticas de cultura, processos e ferramentas com a finalidade de promover a integração das áreas de negócio de desenvolvimento e sustentação de software e operações de TI, para garantir o aumento de produtividade, agilidade, eficiência e disponibilidade dos seus softwares, permitindo que a nossa equipe responda rapidamente às suas necessidades entregando  valor com inovação e melhoria contínua, fazendo uso de tecnologias automatizadas para garantir que as aplicações sejam entregues de forma confiável e segura, facilitando a resolução de problemas rapidamente em caso de falhas.</p>
                        </div>
                    </div>
                    <div className='col-md-4 mb-4'>
                        <div className='d-flex'><img className='flutuar mx-auto' id="" src={weria_ia} alt="nft-gaming" /></div>
                        <div>
                            <h2 className='mb-4 text-center' id='container02_produtos_h2__ia__iot'>Weria IA</h2>
                            <p className='text-justify'>A Inteligência Artificial (IA) tem evoluído rapidamente, e hoje é uma área de pesquisa ativa que envolve a criação de algoritmos e sistemas computacionais capazes de aprender, tomar decisões e realizar tarefas sem intervenção humana. A Weria IA é uma combinação de equipe técnica especializada com a escolha da ferramenta certa dependendo do tipo de tarefa de aprendizado de máquina que se deseja realizar. Fazemos isso por meio da identificação da melhor abordagem de inteligência artificial a ser utilizada, realizando um diagnóstico do tipo de problema a resolver, o conjunto de dados disponíveis, os recursos computacionais disponíveis e os objetivos de negócios específicos​.</p>
                        </div>
                    </div>
                    <div className='col-md-4 mb-4'>
                        <div className='d-flex'><img className=' flutuar mx-auto' id="" src={weria_iot} alt="nft-gaming" /></div>
                        <div>
                            <h2 className='mb-4 text-center' id='container02_produtos_h2__ia__iot'>Weria IoT</h2>
                            <p className='text-justify'>IoT, Internet das Coisas, ou Internet of Things em inglês, permite que as coisas “conversem” entre si e com outros dispositivos conectados à Internet, possibilitando a automação de tarefas, a melhoria da eficiência e a coleta de dados para a tomada de decisões baseadas em dados. Além disso, a IoT está revolucionando vários setores, como a  indústria, a saúde, a agricultura e a segurança.Nessa era de tantas inovações, a Weria IoT veio para entregar soluções que variam de acordo com as necessidades específicas de cada empresa ou usuário. Algumas dessas soluções incluem Smart Home, Wearables, Agtech, Health Tech, Manutenção Preditiva, Cidades Inteligentes e muitas outras. Além dessas soluções customizadas, a Weria IoT possui seus próprios produtos, confira aqui.</p>
                        </div>
                    </div>
                </div>
                <div className='linha' id='linha__inferior'></div>
            </div>
            <Footer />
        </div>
    );
}

const BlogItem = props => (
    <div className="fl-blog fl-item2 col-lg-4 col-md-6">
        <article className="sc-card-article">
            <div className="card-media">
                <Link to="/blog-details"><img src={props.item.img} alt="Axies" /></Link>
            </div>
            <div className="meta-info">
                <div className="author">
                    <div className="avatar">
                        <img src={props.item.imgAuthor} alt="Axies" />
                    </div>
                    <div className="info">
                        <span>Post owner</span>
                        <h6> <Link to="/author-02">{props.item.nameAuthor}</Link> </h6>
                    </div>
                </div>
                <div className="date">{props.item.time}</div>
            </div>
            <div className="text-article">
                <h3><Link to="/blog-details">{props.item.title}</Link></h3>
                <p>{props.item.content}</p>
            </div>
            <Link to="/blog-details" className="sc-button fl-button pri-3"><span>Read More</span></Link>
        </article>
    </div>
)

export default Blog;
